@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300i,400,400i,600i");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

.container {
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 0 15px;
    max-width: 960px;
  }
}

.nav-toggle {
  background-color: #dcb894;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  width: 3.5rem;
  height: 3.5rem;
  padding: 10px;
}

.nav-toggle span {
  background-color: #745637;
  display: block;
  height: 2px;
}

.nav-toggle:hover {
  background-color: #e9d2ba;
}

.nav {
  background-color: #a67c52;
  color: #fff;
  min-height: 3.5rem;
}

.has-shadow {
  box-shadow: rgba(0, 0, 0, 0.6) 0 5px 10px -5px;
}

.nav-menu {
  display: none;
}

.nav-menu.is-active {
  display: flex;
  flex-direction: column;
}

.nav-menu.is-active .nav-item {
  color: #fff;
  text-decoration: none;
  padding: 15px;
  border-top: 1px solid #dcb894;
}

@media (min-width: 768px) {
  .container {
    max-width: 960px;
    margin: 0 auto;
  }
  .nav-toggle {
    display: none;
  }
  .nav-menu, .nav-menu.is-active {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-height: 3.5rem;
  }
  .nav-menu .nav-item, .nav-menu.is-active .nav-item {
    flex: 1;
    color: #fff;
    text-decoration: none;
    text-align: center;
    padding: 19px;
    border-top: none;
    transition: all .3s;
    font-weight: 700;
  }
  .nav-menu .nav-item:hover, .nav-menu.is-active .nav-item:hover {
    background-color: #b99672;
  }
}

header {
  background-color: #a67c52;
  color: #fff;
  font-size: 20px;
}

header h1 {
  display: none;
}

header .header-right {
  display: flex;
  justify-content: space-between;
}

header .header-right .quick-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

header .header-right .quick-links a {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 32px;
  transition: all .3s;
}

header .header-right .quick-links a:hover {
  color: #745637;
}

header .header-right .translate {
  display: none;
}

@media screen and (min-width: 768px) {
  header {
    min-height: 120px;
  }
  header h1 {
    display: block;
    font-style: italic;
    font-weight: 100;
    font-size: 25px;
    padding: 10px;
  }
  header .container {
    display: flex;
  }
  header .container .header-left {
    width: 73%;
  }
  header .container .header-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  header .container .header-right .translate {
    display: inline-block;
    color: #745637;
    background-color: #dcb894;
    padding: 12px;
    text-decoration: none;
    transition: all .3s;
  }
  header .container .header-right .translate:hover {
    color: #fff;
  }
  header .container .header-right .quick-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin-bottom: 10px;
  }
  header .container .header-right .quick-links a {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 32px;
    transition: all .3s;
  }
  header .container .header-right .quick-links a:hover {
    color: #745637;
  }
}

.services {
  background-color: rgba(166, 124, 82, 0.2);
  display: flex;
  flex-direction: column;
  padding: 22px;
}

.services .service {
  flex: 1;
  background-color: #a67c52;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  color: black;
  font-size: 25px;
  font-weight: bold;
  padding: 15px;
  margin: 10px 0;
  transition: all .3s;
}

.services .service:first-of-type {
  margin-top: 0;
}

.services .service:last-of-type {
  margin-bottom: 0;
}

.services .service:hover {
  transform: translate(0, -2px) scale(1.01);
  background-color: #b18961;
  color: #513c27;
  box-shadow: rgba(0, 0, 0, 0.3) 0 20px 10px -10px;
}

@media only screen and (min-width: 768px) {
  .services {
    flex-direction: row;
    position: relative;
    top: -35px;
  }
  .services .service {
    margin: 0 10px;
  }
  .services .service:first-of-type {
    margin-left: 0;
  }
  .services .service:last-of-type {
    margin-right: 0;
  }
}

footer {
  color: #fff;
  text-align: center;
}

footer a {
  color: white;
  text-decoration: none;
  transition: all .3s;
}

footer a:hover {
  color: #a67c52;
}

html, body {
  background-color: #000;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
}

.splash {
  display: none;
}

@media screen and (min-width: 768px) {
  .splash {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
