footer {
  color: $white;
  text-align: center;
  a {
	color: white;
	text-decoration: none;
	transition: all .3s;

	&:hover {
	  color: $brown;
	}
  }
}