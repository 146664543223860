$nav-bg: $brown;
$nav-toggle-burger-bg: #dcb894;
$nav-toggle-burger-text: #745637;

.nav-toggle {
  background-color: $nav-toggle-burger-bg;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  box-sizing: border-box;
  width: 3.5rem;
  height: 3.5rem;
  padding: 10px;
  span {
	background-color: $nav-toggle-burger-text;
	display: block;
	height: 2px;
  }

  &:hover {
	background-color: lighten($nav-toggle-burger-bg, 10%);
  }
}

.nav {
  background-color: $nav-bg;
  color: $white;
  min-height: 3.5rem;
}

.has-shadow {
  box-shadow: rgba(black, 0.6) 0 5px 10px -5px;
}

.nav-menu {
  display: none;
  &.is-active {
	display: flex;
	flex-direction: column;

	.nav-item {
	  color: $white;
	  text-decoration: none;
	  padding: 15px;
	  border-top: 1px solid $nav-toggle-burger-bg;
	}
  }
}

@media (min-width: 768px) {
  .container {
	max-width: 960px;
	margin: 0 auto;
  }

  .nav-toggle {
	display: none;
  }

  .nav-menu, .nav-menu.is-active {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	min-height: 3.5rem;

	.nav-item {
	  flex: 1;
	  color: $white;
	  text-decoration: none;
	  text-align: center;
	  padding: 19px;
	  border-top: none;
	  transition: all .3s;
	  font-weight: 700;

	  &:hover {
		background-color: lighten($nav-bg, 10%);
	  }
	}
  }
}