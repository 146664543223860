.services {
	background-color: rgba(#a67c52, .2);
	display: flex;
	flex-direction: column;
	padding: 22px;

	.service {
		flex: 1;
		background-color: $brown;
		text-decoration: none;
		text-transform: uppercase;
		text-align: center;
		color: black;
		font-size: 25px;
		font-weight: bold;
		padding: 15px;
		margin: 10px 0;
		transition: all .3s;

		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
		
		&:hover {
			transform: translate(0, -2px) scale(1.01);
			background-color: lighten($brown, 5%);
			color: darken(#745637, 10%);
			box-shadow: rgba(black, .3) 0 20px 10px -10px;
		}
	}
}

@media only screen and (min-width: 768px) {
	.services {
		flex-direction: row;
	  	position: relative;
		top: -35px;

		.service {
			margin: 0 10px;

			&:first-of-type {
				margin-left: 0;
			}
			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}