header {
  background-color: $brown;
  color: $white;
  font-size: 20px;

  h1 {
	display: none;
  }

  .header-right {
	display: flex;
	justify-content: space-between;

	.quick-links {
	  display: flex;
	  justify-content: flex-start;
	  align-items: center;

	  a {
		width: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;

		color: $white;
		font-size: 32px;
		transition: all .3s;

		&:hover {
		  color: $nav-toggle-burger-text;
		}
	  }
	}

	.translate {
	  display: none;
	}
  }
}

@media screen and (min-width: 768px) {
  header {
	min-height: 120px;
	h1 {
	  display: block;
	  font-style: italic;
	  font-weight: 100;
	  font-size: 25px;
	  padding: 10px;
	}
	.container {
	  display: flex;
	  .header-left {
		width: 73%;
	  }

	  .header-right {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.translate {
		  display: inline-block;
		  color: $nav-toggle-burger-text;
		  background-color: $nav-toggle-burger-bg;
		  padding: 12px;
		  text-decoration: none;
		  transition: all .3s;

		  &:hover {
			color: $white;
		  }
		}

		.quick-links {
		  display: flex;
		  justify-content: space-between;
		  align-items: center;
		  width: 60%;
		  margin-bottom: 10px;

		  a {
			width: 32px;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;

			color: $white;
			font-size: 32px;
			transition: all .3s;

			&:hover {
			  color: $nav-toggle-burger-text;
			}
		  }
		}
	  }
	}
  }
}