@import "variables";
@import "reset";
@import "components/layout";
@import "components/nav";
@import "header";
@import "content";
@import "footer";

html, body {
  background-color: $black;
  color: $white;
  font-family: 'Source Sans Pro', sans-serif;
}

.splash {
  display: none;
}

@media screen and (min-width: 768px) {
  .splash {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
